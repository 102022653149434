import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BusinessDistrictsHeroSectionComponent from '../components/business-districts/BusinessDistrictsHeroSectionComponent';
import BusinessDistrictsContentComponent from '../components/business-districts/BusinessDistrictsContentComponent';
import ResearchPublicationComponent from '../components/home-page/ResearchPublicationComponent';

const StyledRow = styled(Row)`
  margin: 32px 0px;
`;

function BusinessDistrictViewPage({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiPublications(
        limit: 4
        sort: { order: DESC, fields: publish_date }
      ) {
        nodes {
          url_path
          title
          strapiId
          publish_date(formatString: "MMMM DD, YYYY")
          description
          image_path
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title={pageContext.cbdDetails.district_name}
        description={pageContext.cbdDetails.meta_description}
        meta={[
          {
            property: 'og:image',
            content: pageContext.cbdDetails.thumbnail_photo,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: pageContext.cbdDetails.meta_keywords,
          },
        ]}
      />
      <BusinessDistrictsHeroSectionComponent
        cbdDetails={pageContext.cbdDetails}
      />
      <BusinessDistrictsContentComponent cbdDetails={pageContext.cbdDetails} />
      <StyledRow type="flex" justify="center">
        <Col xxl={14} md={20} sm={23} xs={23}>
          <ResearchPublicationComponent
            title="Real Estate Publications"
            publications={data.allStrapiPublications.nodes}
          />
        </Col>
      </StyledRow>
    </Layout>
  );
}

export default BusinessDistrictViewPage;
