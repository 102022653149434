import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const StyledCoverImage = styled.div`
  background-image: ${props => `linear-gradient(
      rgba(0, 0, 0, 0.32),
      rgba(0, 0, 0, 0.32)
    ),
    url(${props.coverImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 320px;

  .styled-row {
    height: 100%;
    color: ${props => props.theme.whitePalette.original};
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.32);
  }

  h1 {
    margin-bottom: 4px;
    margin-top: 64px;
    text-align: center;
  }

  h4 {
    font-weight: 300;
    margin-bottom: 0;
    text-align: center;
    font-family: Proxima Nova;
  }
`;

function BusinessDistrictsHeroSectionComponent({ cbdDetails }) {
  return (
    <Row type="flex" justify="center">
      <Col xxl={14} md={22} sm={24} xs={24}>
        <StyledCoverImage coverImage={cbdDetails.banner_photo}>
          <Row type="flex" align="middle" className="styled-row">
            <Col span={24}>
              <h1>{cbdDetails.district_name}</h1>
              <h4>{cbdDetails.sub_name}</h4>
            </Col>
          </Row>
        </StyledCoverImage>
      </Col>
    </Row>
  );
}

export default BusinessDistrictsHeroSectionComponent;
