import React from 'react';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';

const StyledRow = styled(Row)`
  margin: 32px 0px;

  .styled-card {
    border: ${props => `2px solid ${props.theme.primeBluePalette.original}`};
    padding: 12px 10px;
    height: 100%;

    h5 {
      color: ${props => props.theme.primeBluePalette.original};
      margin-bottom: 8px;
    }

    .styled-info {
      line-height: 22px;

      :nth-last-child(n + 2) {
        margin-bottom: 16px;
      }
    }
  }
`;

function BusinessDistrictsContentComponent({ cbdDetails }) {
  const features = [
    {
      id: 1,
      title: 'TOTAL OFFICE SUPPLY (2020 H1 in sqm)',
      value: cbdDetails.total_office_supply,
    },
    {
      id: 2,
      title: 'TOTAL RETAIL MALL SUPPLY (2020 H1 in sqm)',
      value: cbdDetails.total_retail_mall_supply,
    },
    {
      id: 3,
      title: '2019 CMCI Ranking',
      value: cbdDetails.cmci_ranking,
    },
    {
      id: 4,
      title: 'LAND AREA',
      value: cbdDetails.land_area,
    },
    {
      id: 5,
      title: 'POPULATION',
      value: cbdDetails.population,
    },
  ];

  return (
    <StyledRow type="flex" justify="center">
      <Col xxl={14} md={20} sm={23} xs={23}>
        <h3>Description</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: cbdDetails.content,
          }}
        />
        <Divider />
        <Row type="flex" gutter={[16, 16]}>
          {features
            .filter(detail => !!detail.value)
            .map(data => (
              <Col md={8} sm={12} xs={24} key={data.id}>
                <div className="styled-card">
                  <h5>{data.title}</h5>
                  {data.value.split(' | ').map(info => (
                    <div className="styled-info" key={info}>
                      {info}
                    </div>
                  ))}
                </div>
              </Col>
            ))}
        </Row>
        {/* <Divider />
        <h3>Key Highlights</h3>
        <Row type="flex" gutter={[16, 16]}>
          <Col md={8} sm={12} xs={24}></Col>
          <Col md={16} sm={12} xs={24}>
            <h4>Tomas Morato</h4>
            <p>
              Located in the heart of Muntinlupa City, Alabang is home to
              numerous PEZA accredited buildings. Alabang also serves as the
              North's access to the southern parts of Luzon, in an effort for
              BPOs to expand to fringe areas. One of its notable developments is
              Filinvest City, 244-hectare township develop, within which
              residential, office and business, leasure and retail, education,
              and wellness are fully integrated.
            </p>
          </Col>
        </Row>
        <Divider /> */}
      </Col>
    </StyledRow>
  );
}

export default BusinessDistrictsContentComponent;
